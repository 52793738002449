import styled from 'styled-components'
import withTranslation from 'common/HOCs/withTranslation'
import {useRouter} from 'next/router'

export const SWITCH_BUTTONS = {
  signin: 'signin',
  signup: 'signup'
}

const SwitchWrapper = styled.div`
  font-family: 'Nunito Sans', sans-serif;
  color: #253044;
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  margin: 24px 0 40px 0;
  background: #f9f9f9;
  border-radius: 16px;

  @media (min-width: 1000px) {
    display: none;
  }
`

const SwitchButton = styled.button`
  width: 50%;
  height: 46px;
  border-radius: 12px;
  border: 0;
  margin: 4px;
  padding: 13px 0;
  background: ${props => (props.highlighted ? '#253044' : '#f9f9f9')};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: ${props => (props.highlighted ? '#ffffff' : '#253044')};
  justify-content: center;
  cursor: pointer;
`

const SignupLoginSwitch = ({t, highlightedButton}) => {
  const router = useRouter()
  const handleClick = href => {
    router.push(href)
  }

  return (
    <SwitchWrapper>
      <SwitchButton
        onClick={() => handleClick('/signup')}
        highlighted={highlightedButton === SWITCH_BUTTONS.signup}
      >
        {t('SIGN_UP_BUTTON_TEXT')}
      </SwitchButton>
      <SwitchButton
        onClick={() => handleClick('/login')}
        highlighted={highlightedButton === SWITCH_BUTTONS.signin}
      >
        {t('LOGIN_BUTTON_TEXT')}
      </SwitchButton>
    </SwitchWrapper>
  )
}

export default withTranslation(SignupLoginSwitch)
