import React, {useEffect} from 'react'

import {Router} from 'i18n'
import {withRouter} from 'next/router'
import Noty from 'noty'
import {HOME} from 'routes.json'

import withTranslation from 'common/HOCs/withTranslation'
import withUserContext from 'common/HOCs/withUserContext'
import {getSeoConfig, isEmptyObject, notificate, objectToQueryString} from 'common/helpers'
import {CPLayout} from 'components/CPLayout'
import CPLogin from 'components/CPSignUpLogin/Login'
import {DotsLoader} from 'components/common/CPLoaders'
import {NOTIFICATIONS_TYPE} from 'consts.json'
import {navigateToLogin} from 'lib/navigation'
import {activateAccount} from 'services/userService'

const Login = props => {
  const {t, user, router, seoConfig, redirectUrl} = props

  useEffect(() => {
    if (user.isLogged) {
      Router.replace(redirectUrl || HOME.link)
    }
  }, [user.isLogged])

  useEffect(() => {
    if (router && router.query && router.query.reset_password) {
      notificate(NOTIFICATIONS_TYPE.SUCCESS, t('RESET_PASSWORD_SUCCESS'), {killer: true})
    }
    if ('accountActivationSuccess' in props) {
      notificate(NOTIFICATIONS_TYPE.SUCCESS, t('ACCOUNT_ACTIVATED_MESSAGE'), {killer: true})
      navigateToLogin({redirectUrl})
    }

    if ('grantByCode' in localStorage) {
      new Noty({
        type: 'success',
        layout: 'bottomLeft',
        text: t('ITEMS_TO_BE_GRANTED_NOTIFICATION'),
        killer: true,
        closeWith: ['click', 'button']
      }).show()
    }
  })

  return (
    <CPLayout seoConfig={seoConfig}>
      {user.isLogged ? <DotsLoader /> : <CPLogin redirectUrl={redirectUrl} />}
    </CPLayout>
  )
}

Login.getInitialProps = async ctx => {
  const seoConfig = getSeoConfig({
    ctx,
    title: 'LOGIN_BUTTON_TEXT'
  })
  const redirectUrl = ctx.query.redirect_url || null

  try {
    const {token} = ctx.query
    if (token) {
      await activateAccount(token)
      return {accountActivationSuccess: true, redirectUrl}
    }
  } catch (e) {
    const query =
      'query' in ctx && !isEmptyObject(ctx.query) ? `${objectToQueryString(ctx.query)}` : ''
    // eslint-disable-next-line no-magic-numbers
    ctx.res.writeHead(302, {Location: `/activation-failed${query}`})
    ctx.res.end()
  }

  return {seoConfig, redirectUrl}
}

export default withRouter(withUserContext(withTranslation(Login)))
