import styled from 'styled-components'

export const ButtonLink = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  padding: 15px 32px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  text-decoration: none;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary1};

  :hover {
    background-color: ${props => props.theme.colors.primary1Hover};
  }
`
