import GoogleLogin from 'react-google-login'
import styled from 'styled-components'

import withTranslation from 'common/HOCs/withTranslation'
import config from 'config'

import {genericResponseHandler} from '../../CPLoginAndSignUp/common/helpers'

const DISABLED_OPACITY = 0.3

const SignUpFormNetworkButton = styled.button`
  width: 100%;
  height: 60px;
  border-radius: 12px;
  border: 0;
  padding: 18px 24px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? DISABLED_OPACITY : 1)};
  margin: 4px 0 4px 0;
  background: #f9f9f9;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #253044;
  display: inline-flex;
  align-items: center;
`

const SocialNetworkLogo = styled.img`
  margin-right: 16px;
`

const GoogleLoginButton = ({t, label, redirectOptions, updateUser, showBanModal}) => {
  return (
    <GoogleLogin
      clientId={config.GOOGLE_APP_ID}
      render={renderProps => (
        <SignUpFormNetworkButton onClick={renderProps.onClick}>
          <SocialNetworkLogo src="/static/images/sign-up-google-logo.svg" />
          {label}
        </SignUpFormNetworkButton>
      )}
      buttonText="Login"
      onSuccess={response =>
        genericResponseHandler(response, 'GOOGLE', t, redirectOptions, updateUser, showBanModal)
      }
      onFailure={response => genericResponseHandler(response, 'GOOGLE', t, redirectOptions)}
      cookiePolicy={'single_host_origin'}
    />
  )
}

export default withTranslation(GoogleLoginButton)
