import React, {useState} from 'react'
import styled from 'styled-components'

const InnerInputWrapper = styled.div`
  position: relative;
`

const InnerInput = styled.input`
  border-width: 2px;
  border-style: solid;
  border-color: ${props => (props.error ? '#C82853' : '#d7d7d7')};
  border-radius: 12px;
  padding: 15px 24px;
  font-size: 16px;
  resize: vertical;
  font-family: 'Nunito Sans', sans-serif;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: ${props => (props.showErrorPadding ? '20px' : '4px')};
  margin-left: 0;

  &::placeholder {
    color: #d7d7d7;
    font-size: 14px;
    border-radius: 16px;
    font-family: 'Nunito Sans', sans-serif;
  }

  &:focus {
    outline: none;
    background-color: #ffffff;
    border-color: ${props => (props.error ? '#C82853' : '#253044')};
  }
`

const ErrorMessage = styled.p`
  position: absolute;
  right: 0;
  bottom: 2px;
  margin: 0;
  display: ${props => (props.displayError ? 'show' : 'none')};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #c82853;
`

const ShowIcon = styled.img`
  position: absolute;
  right: 24px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const input = ({
  className,
  type = 'text',
  value = '',
  onChange = () => {},
  placeholder = '',
  showError = false,
  errorMessage = '',
  showErrorPadding = true,
  onKeyPress = () => {}
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <InnerInputWrapper className={className}>
      <InnerInput
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        error={showError}
        showErrorPadding={showErrorPadding}
        onKeyPress={onKeyPress}
      />
      <ErrorMessage displayError={showError}>{errorMessage}</ErrorMessage>
      {type === 'password' ? (
        <ShowIcon
          src={
            showPassword
              ? '/static/images/sign-up-eye-crossed.svg'
              : '/static/images/sign-up-eye.svg'
          }
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : null}
    </InnerInputWrapper>
  )
}

export default input
