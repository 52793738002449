import React from 'react'

import {useTranslation} from 'react-i18next'

import consts from 'consts.json'

import * as S from './Styled'

const capitalizeFirstLetter = sentence => {
  return sentence.charAt(0).toUpperCase() + sentence.slice(1)
}

const ContactSupportButton = ({classname}) => {
  const {t} = useTranslation()
  return (
    <S.ButtonLink classname={classname} href={`mailto:${consts.SUPPORT_EMAIL_CULTURAL_PLACES}`}>
      {capitalizeFirstLetter(t('CONTACT_SUPPORT'))}
    </S.ButtonLink>
  )
}

export default ContactSupportButton
