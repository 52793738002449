import styled from 'styled-components'

const DISABLED_OPACITY = 0.3

export const LoginWrapper = styled.section`
  font-family: 'Nunito Sans', sans-serif;
  color: #253044;
  overflow: hidden;
  max-width: 1152px;
  display: flex;
  flex-direction: row;
  margin: 32px auto 0 auto;
`

export const LoginFormWrapper = styled.div`
  width: 50%;
  display: flex;

  @media (max-width: 1000px) {
    width: 100%;
    justify-content: center;
  }
`

export const LoginImageWrapper = styled.div`
  width: 50%;
  height: fit-content;
  position: relative;
  text-align: center;
  background-color: orange;
  border-radius: 50% 50% 50% 0;

  @media (max-width: 1000px) {
    display: none;
  }
`

export const LoginImage = styled.img`
  display: block;
  width: -webkit-fill-available;
  border-radius: 50% 50% 50% 0;
  opacity: 0.15;
`

export const LoginOutterForm = styled.div`
  margin: auto 24px auto 24px;
  padding-bottom: 24px;

  ${props => props.theme.mediaQuery.lg} {
    padding-bottom: 0;
  }

  @media (min-width: 1001px) {
    margin: auto 98px auto 122px;
  }
`

export const LoginInnerForm = styled.form``

export const LoginHeading = styled.h4`
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 49px;
  letter-spacing: -0.02em;
  color: #253044;
  margin: 0 0 16px 0;
`

export const LoginSubheading = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #2c3951;
  margin: 0 0 30px 0;
`

export const LoginButton = styled.button`
  width: 100%;
  height: 60px;
  background-color: #253044;
  border-radius: 12px;
  color: #ffffff;
  border: 0;
  padding: 18px 40px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? DISABLED_OPACITY : 1)};
  margin: 4px 0 4px 0;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
`

export const LoginOr = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #253044;
  opacity: 0.4;
  text-align: center;
  margin: 12px 0 12px 0;
`

export const LoginTerms = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #2c3951;
`

export const LoginTermsLink = styled.a`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #faa929;
`

export const ForgotPasswordLink = styled.a`
  text-decoration: none;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #253044;
  float: right;
  margin-bottom: 4px;
`

export const ExistingAccount = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: #253044;
`

export const SignUpLink = styled.a`
  font-weight: bold;
  cursor: pointer;
`
