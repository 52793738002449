import {Router} from 'i18n'
import {EXPERIENCE, GUIDE, HOME, LOG_IN, PROFILE_ENABLE_WITHDRAWAL, SIGN_UP} from 'routes.json'

const GUIDE_PLAY_BASE_URL = GUIDE.PLAY.link
const EXPERIENCE_VIEW_BASE_URL = EXPERIENCE.VIEW.link

const resolveGuidePlayPageRawUrl = ({slug}) => `${GUIDE_PLAY_BASE_URL}/[slug]?slug=${slug}`
const resolveGuidePlayPagePrettyUrl = ({slug}) => `${GUIDE_PLAY_BASE_URL}/${slug}`
const resolveExperienceRawUrl = ({slug}) => `${EXPERIENCE_VIEW_BASE_URL}/[slug]?slug=${slug}`
const resolveExperiencePrettyUrl = ({slug}) => `${EXPERIENCE_VIEW_BASE_URL}/${slug}`

const navigate = (href, {asHref = href, openInNewTab, replace} = {}) => {
  if (openInNewTab) {
    return window.open(href, '_blank')
  }

  const action = replace ? Router.replace : Router.push
  action(href, asHref)
}

export const navigateToGuidePlay = ({slug}, options = {}) => {
  const href = resolveGuidePlayPageRawUrl({slug})
  const asHref = resolveGuidePlayPagePrettyUrl({slug})
  navigate(href, {...options, asHref})
}

export const navigateToExperience = ({slug}, options = {}) => {
  const href = resolveExperienceRawUrl({slug})
  const asHref = resolveExperiencePrettyUrl({slug})
  navigate(href, {...options, asHref})
}

export const navigateToHostExperienceList = (options = {}) => {
  navigate(EXPERIENCE.LIST.link, options)
}

export const navigateToHome = options => {
  navigate(HOME.link, options)
}

export const navigateToLogin = ({redirectUrl, ...otherOptions} = {}) => {
  let href = LOG_IN.link

  if (redirectUrl) {
    href = `${href}?redirect_url=${encodeURIComponent(redirectUrl)}`
  }

  navigate(href, {
    replace: true,
    ...otherOptions
  })
}

export const navigateToSignUp = ({redirectUrl, ...otherOptions} = {}) => {
  let href = SIGN_UP.link

  if (redirectUrl) {
    href = `${href}?redirect_url=${encodeURIComponent(redirectUrl)}`
  }

  navigate(href, {
    replace: true,
    ...otherOptions
  })
}

export const navigateToLoginWithRedirectUrl = (redirectUrl, options = {}) =>
  navigateToLogin({
    redirectUrl,
    ...options
  })

export const navigateToLoginWithRedirectBack = (options = {}) => {
  const redirectUrl = encodeURIComponent(Router.asPath)

  return navigateToLogin({
    redirectUrl,
    ...options
  })
}

export const navigateToEnableWithdrawal = options => {
  navigate(PROFILE_ENABLE_WITHDRAWAL.link, options)
}
