import React, {useState} from 'react'

import Noty from 'noty'
import {LOG_IN} from 'routes.json'

import DangerIconSvg from 'assets/icons/info-modal-danger.svg'
import withTranslation from 'common/HOCs/withTranslation'
import {getHost, isEmailValid, notificate} from 'common/helpers'
import {resolveLinkUrl} from 'common/helpers/url'
import useDangerInfoModal from 'components/common/CPModal/components/hooks/useDangerInfoModal'
import {NOTIFICATIONS_TYPE} from 'consts.json'
import {navigateToSignUp} from 'lib/navigation'
import {login} from 'services/auth'
import {getMinifiedProfile, logIn, resendActivation} from 'services/userService'

import ContactSupportButton from './components/ContactSupportButton'
import FacebookLoginButton from './components/facebookLogin'
import GoogleLoginButton from './components/googleLogin'
import Input from './components/input'
import SignupLoginSwitch, {SWITCH_BUTTONS} from './components/signupLoginSwitch'

import * as S from './Styled'

const NOTIFICATION_TIMEOUT = 10000

const Login = ({t, redirectUrl, i18n}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailValid, setEmailValid] = useState(false)
  const currentLanguage = i18n.language
  const redirectOptions = {redirectUrl}

  const {Modal: DangerInfoModal, show: showDangerInfoModal} = useDangerInfoModal({
    id: 'cp-user-banned-danger-modal',
    closeOnOutsideClick: true,
    icon: DangerIconSvg
  })
  const handleSubmit = event => {
    event.preventDefault()
    const logInFormData = {
      email: email,
      password: password
    }

    logIn(
      'CP',
      logInFormData,
      async logInData => {
        const {token} = logInData.data
        try {
          const user = await getMinifiedProfile(token)
          if (user.isBanned) {
            notificate(NOTIFICATIONS_TYPE.ERROR, t('USER_BANNED_LOGIN_MESSAGE'))
          } else {
            notificate(NOTIFICATIONS_TYPE.SUCCESS, t('SIGN_IN_SUCCESS'))
            login({token, user, redirectOptions})
          }
        } catch (e) {
          notificate(NOTIFICATIONS_TYPE.ERROR, t('LOGIN_ERROR'))
        }
      },
      error => {
        if (error === 'USER_NOT_ACTIVATED') {
          const noty = new Noty({
            type: 'success',
            layout: 'topRight',
            text: t('ACCOUNT_NOT_ACTIVATED'),
            killer: true,
            closeWith: 'button',
            buttons: [
              Noty.button(
                t('RESEND_ACTIVATION_LINK'),
                'noty_resend_activation_button',
                function () {
                  const resendFormData = Object.assign(logInFormData, {
                    link: `${getHost()}${LOG_IN.link}`
                  })

                  resendActivation(
                    resendFormData,
                    () => {
                      noty.close()
                      notificate(
                        NOTIFICATIONS_TYPE.SUCCESS,
                        t('RESEND_ACTIVATION_LINK_OK_MESSAGE'),
                        {
                          killer: true,
                          layout: 'topRight'
                        }
                      )
                    },
                    () => {
                      noty.close()
                      notificate(
                        NOTIFICATIONS_TYPE.ERROR,
                        t('RESEND_ACTIVATION_LINK_ERROR_MESSAGE'),
                        {
                          killer: true,
                          layout: 'topRight'
                        }
                      )
                    }
                  )
                }
              )
            ]
          }).show()

          setTimeout(() => {
            noty.close()
          }, NOTIFICATION_TIMEOUT)
        } else if (error === 'USER_BANNED') {
          showDangerInfoModal(true)
        } else {
          notificate(NOTIFICATIONS_TYPE.ERROR, t('LOGIN_ERROR'))
        }
      }
    )
  }

  const handleChange = fieldName => event => {
    if (fieldName === 'email') {
      if (isEmailValid(event.target.value)) {
        setEmailValid(true)
      } else {
        setEmailValid(false)
      }
      setEmail(event.target.value)
    }
    if (fieldName === 'password') {
      setPassword(event.target.value)
    }
  }

  const goToSignUp = () => {
    navigateToSignUp({redirectUrl})
  }

  return (
    <S.LoginWrapper>
      <S.LoginImageWrapper>
        <S.LoginImage src="/static/images/login.png" />
      </S.LoginImageWrapper>
      <S.LoginFormWrapper>
        <S.LoginOutterForm onSubmit={handleSubmit}>
          <SignupLoginSwitch highlightedButton={SWITCH_BUTTONS.signin} />
          <S.LoginHeading>{t('LOGIN_PAGE_TITLE')}</S.LoginHeading>
          <S.LoginSubheading>{t('LOGIN_TO_USE_FEATURES')}</S.LoginSubheading>
          <GoogleLoginButton
            label={t('GOOGLE_SIGN_IN_BUTTON_TEXT')}
            showBanModal={showDangerInfoModal}
            redirectOptions={redirectOptions}
          />
          <FacebookLoginButton
            label={t('FACEBOOK_SIGN_IN_BUTTON_TEXT')}
            showBanModal={showDangerInfoModal}
            redirectOptions={redirectOptions}
          />
          <S.LoginOr>{t('OR')}</S.LoginOr>
          <S.LoginInnerForm>
            <Input
              type="text"
              value={email}
              onChange={handleChange('email')}
              placeholder={t('YOUR_EMAIL')}
              showError={!emailValid && email != ''}
              errorMessage={t('EMAIL_VALIDATION_MESSAGE')}
            />
            <Input
              type="password"
              value={password}
              onChange={handleChange('password')}
              placeholder={t('PASSWORD_FIELD_PLACEHOLDER')}
              showErrorPadding={false}
            />
            <S.ForgotPasswordLink href="forgot-password">
              {t('FORGOT_PASSWORD_LINK_TEXT')}
            </S.ForgotPasswordLink>
            <S.LoginButton disabled={!emailValid} type="submit">
              {t('LOGIN_BUTTON_TEXT')}
            </S.LoginButton>
          </S.LoginInnerForm>
          <S.LoginTerms>
            {t('SIGN_UP_AGREEMENT')}
            <br />
            <S.LoginTermsLink
              target="_blank"
              href={resolveLinkUrl('TERMS_AND_CONDITIONS', currentLanguage)}
            >
              {t('TERMS_AND_CONDITIONS')}
            </S.LoginTermsLink>
            &nbsp;{t('AND')}&nbsp;
            <S.LoginTermsLink
              target="_blank"
              href={resolveLinkUrl('PRIVACY_POLICY', currentLanguage)}
            >
              {t('PRIVACY_POLICY')}
            </S.LoginTermsLink>
          </S.LoginTerms>
          <S.ExistingAccount>
            {t('DONT_HAVE_ACCOUNT')}
            <S.SignUpLink onClick={goToSignUp}> {t('SIGN_UP')}</S.SignUpLink>
          </S.ExistingAccount>
        </S.LoginOutterForm>
      </S.LoginFormWrapper>
      <DangerInfoModal Button={ContactSupportButton}>
        {t('USER_BANNED_LOGIN_MESSAGE')}
      </DangerInfoModal>
    </S.LoginWrapper>
  )
}

export default withTranslation(Login)
