import React, {useCallback} from 'react'

import DangerIconSvg from 'assets/icons/info-modal-failure.svg'
import DangerButtonPrimary from 'components/common/buttons/DangerPrimary'

import useBaseInfoModal from '../useBaseInfoModal'

function useDangerInfoModal(options = {}) {
  const {Modal: BaseInfoModal, isShow, hide, ...rest} = useBaseInfoModal({
    clickOutsideToHide: true,
    ...options
  })

  const Modal = useCallback(
    ({children, Button, buttonText = 'CONFIRM', onButtonClick}) => {
      const onButtonClickFunction = onButtonClick ? onButtonClick : hide
      const ModalButton = Button ? Button : DangerButtonPrimary

      return (
        <>
          <BaseInfoModal
            Icon={options.icon ? options.icon : DangerIconSvg}
            buttonText={buttonText}
            onButtonClick={onButtonClickFunction}
            Button={ModalButton}
          >
            {children}
          </BaseInfoModal>
        </>
      )
    },
    [isShow]
  )

  return {Modal, isShow, hide, ...rest}
}

export default useDangerInfoModal
